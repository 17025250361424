import * as React from 'react';
import CustomButton from './components/CustomButton';
import Fade from '@mui/material/Fade';
import { Grid, TextField, Button } from '@mui/material';
import InstructionPanel from './components/InstructionPanel';
import LoadingScreen from './components/LoadingScreen';
import ReconsentForm from './components/ReconsentForm';
import deployment_config from './deployment_config.json';
import { Helmet } from 'react-helmet';
import { storeEmail, getEmail } from './utils'; // Import the updated getEmail

export default function EmailForm({ email, setEmail, setAge, setCurrentForm, reconsent, setReconsent }) {
    const [emailError, setEmailError] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [showConsentForm, setShowConsentForm] = React.useState(false);
    const apiBaseUrl = deployment_config.apiBaseUrl;

    // Fetch the decrypted email when the component mounts
    React.useEffect(() => {
        async function fetchDecryptedEmail() {
            const decryptedEmail = await getEmail();  // Retrieve the decrypted email
            if (decryptedEmail) {
                setEmail(decryptedEmail); // Set the decrypted email in state
            }
        }
        fetchDecryptedEmail();
    }, [setEmail]);

    const clear = () => {
        setEmail('');
        setAge(null);
    }

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const isValidEmail = emailRegex.test(value);

        setEmailError(!isValidEmail);
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (email && !emailError) {
                setLoading(true);
                submitEmail();
            }
        }
    }

    const submitEmail = () => {
        storeEmail(email);  // Store the email using the new utility function
    
        const jsonObject = {
            "email": email,
            "reconsent": reconsent
        };
    
        console.log("reconsent: " + reconsent);
    
        fetch(apiBaseUrl + '/send-token-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jsonObject)
        })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else if (response.status === 303) {
                response.json().then(data => {
                    setCurrentForm('user');
                });
            } else if (response.status === 307) {
                alert("Maintenance is ongoing, please join back later. See discord for status update!")
                throw new Error('Server in maintenance, please join back later. See discord for status update!');
            } else if (response.status === 308) {
                alert("All licenses are occupied, please join back later!")
                throw new Error('All licenses are occupied, please join back later!');
            } else if (response.status === 305) {
                throw new Error('ConsentRequired');
            } else if (response.status === 304) {
                alert("PLAICraft thinks you're still playing on the account with this email, probably because you just disconnected or you recently quit and are rapidly rejoining.  Our data gets messed up if you use the same email in two simultaneous sessions and it takes us some time to clean up after disconnects and quits.  Please try a different email or wait a few minutes and try again.");
                throw new Error();
            } else {
                response.json().then(data => {
                    let msg = JSON.stringify(data);
                    if (msg.includes("mail")) {
                        alert("Something went wrong with your email, please check the spelling.");
                    } else if (msg.includes("Error")) {
                        alert(msg);
                    } else {
                        alert("Something went wrong... please report this and describe what you were doing when this error occurred.");
                    }
                });
                throw new Error();
            }
        })
        .then((data) => {
            setCurrentForm('token');
        })
        .catch((error) => {
            if (error.message === 'ConsentRequired') {
                setLoading(false);
                setShowConsentForm(true);
            } else {
                console.error('Error:', error);
                setLoading(false);
            }
        });
    };
    
    // Handle reconsent logic
    React.useEffect(() => {
        if (reconsent && !showConsentForm) {
            submitEmail();
        }
    }, [reconsent]);

    return (
        <>
            <Helmet>
                <title>Free Minecraft For AI Research</title>
                <meta name="description" content="Enter your email to get free access to Minecraft and join the community." />
            </Helmet>
            {isLoading ?
                <Fade in={isLoading} timeout={300}>
                    <div>
                        <LoadingScreen />
                    </div>
                </Fade>
                :
                <form noValidate autoComplete="off" onKeyDown={handleKeyPress}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <InstructionPanel
                                instructions={
                                    <ol>
                                        <li>Enter your email to play free Minecraft that contributes to research.</li>
                                    </ol>
                                }
                                iframe={
                                    <iframe
                                        width="100%"
                                        height="266"
                                        src="https://www.youtube.com/embed/PdIjQAhQ3Xk?autoplay=1"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                                        allowFullScreen
                                    >
                                    </iframe>
                                }
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                error={emailError}
                                helperText={emailError ? "Invalid email address" : ""}
                                label="Email"
                                variant="outlined"
                                fullWidth
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </Grid>
                        <Grid container item direction="row" alignItems="center" spacing={0} sx={{ display: "flex", justifyContent: "center" }}>
                            <Grid item>
                                <CustomButton
                                    isDisabled={!email || emailError}
                                    content="Submit"
                                    color="success"
                                    onClick={() => {
                                        setLoading(true);
                                        submitEmail();
                                    }}
                                />
                            </Grid>
                            <Grid item ml={2}>
                                <CustomButton
                                    isDisabled={false}
                                    content="Clear"
                                    color="primary"
                                    onClick={clear}
                                />
                            </Grid>
                        </Grid>
                        {showConsentForm && (
                            <Grid item>
                                <ReconsentForm consent={reconsent} setConsent={(value) => {
                                    setReconsent(value);
                                    setShowConsentForm(false);
                                    setLoading(true);
                                }} />
                                {!reconsent && <Button color="error">Consent terms have been updated! Approval is required to proceed.</Button>}
                                {reconsent && <Button color="success">Consent Granted</Button>}
                            </Grid>
                        )}
                    </Grid>
                </form>
            }
        </>
    );
}
