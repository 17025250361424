import * as React from 'react';
import { Typography, Box, Container, IconButton, Modal, Button, Link, useMediaQuery } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import deployment_config from './deployment_config.json';
import { useTheme } from '@mui/material/styles';

export default function ProgressBar({ instanceId, region, isRedirecting, setRedirecting }) {
    const [progress, setProgress] = React.useState(0);
    const [message, setMessage] = React.useState('Initializing plaiground... (approx. 5 minutes)');
    const [msgTimer, setMsgTimer] = React.useState(null);
    const [open, setOpen] = React.useState(true);
    const [counter, setCounter] = React.useState(0);
    const [readyForRedirect, setReadyForRedirect] = React.useState(false);
    const [instanceIP, setInstanceIP] = React.useState(null);
    const [instructionIndex, setInstructionIndex] = React.useState(0);
    const [showAllInstructions, setShowAllInstructions] = React.useState(false);
    const apiBaseUrl = deployment_config.apiBaseUrl;

    const instructions = [
        "PLEASE READ THE INSTRUCTIONS CAREFULLY. Failing to follow might results in polluted data and you'll be banned from further access.",
        "Once the loading is done, you will be automatically redirected to the VM that runs Minecraft",
        "Make sure you have a mic and headphone, and make sure your background is quiet, we want and only want to hear your voice.",
        "If you don't see Minecraft at first, give it a few seconds before it starts up.",
        "You need to CLICK in the middle of the screen a few times before your mouse can work and you can play!<br />" +
        "You need to CLICK in the middle of the screen a few times before your mouse can work and you can play!<br />" +
        "You need to CLICK in the middle of the screen a few times before your mouse can work and you can play!<br />" +
        "You need to CLICK in the middle of the screen a few times before your mouse can work and you can play!<br />" +
        "You need to CLICK in the middle of the screen a few times before your mouse can work and you can play!",
        "Remember, do NOT type CTRL-W or refresh your page while you play.",
        "Available commands to you are: /tpa for teleporting, /msg for private messages, /ITF for invisible item frames, /minigames to access arena for minigames.",
        "Once you are finished, please close your browser tab. You will need to long press the esc key to exit the fullscreen.",
        "If you die in the game, make sure you click Respawn, do NOT return to title screen or close the tab immediately. Your game state might not be saved.",
        "If you have any questions or any issues, please contact us on Discord."
    ];

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const get_ip_address = async (instance) => {
        const response = await fetch(apiBaseUrl + '/get-ec2-instance-status', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ instance_id: instance, region_name: region })
        });
        const data = await response.json();
        setInstanceIP(data.state);
        return data.state;
    };

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 99) {
                    clearInterval(timer);
                    return 99;
                }
                return Math.min(oldProgress + 3, 99);
            });

            get_ip_address(instanceId).then((state) => {
                if (["initializing"].includes(state) || !state || state === "None" || state === "null") {
                    setCounter(oldCounter => {
                        if (oldCounter > 30 || ["impaired", "insufficient-data", "not-applicable"].includes(state)) {
                            alert("Something went wrong. Please try again.");
                            return oldCounter;
                        }
                        return oldCounter + 1;
                    });
                } else {
                    const audio = new Audio(process.env.PUBLIC_URL + '/ready.mp3');
                    audio.play().then(() => {
                        setReadyForRedirect(true);
                    }).catch((error) => {
                        console.error("Error playing sound:", error);
                        setReadyForRedirect(true);
                    });
                }
            });
        }, 10000);

        return () => clearInterval(timer);
    }, [instanceIP, apiBaseUrl]);

    React.useEffect(() => {
        if (readyForRedirect) {
            setRedirecting(true);
            setProgress(100);
            setMessage("Happy Mining!");
            clearInterval(msgTimer);
            setTimeout(() => {
                window.location.href = `/plaiground/index.html?ip=${instanceIP}`;
            }, 100);
        }
    }, [readyForRedirect, setRedirecting, instanceIP]);

    const handleOpen = () => {
        setOpen(true);
        setShowAllInstructions(true);
        setInstructionIndex(0);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleNext = () => {
        if (instructionIndex < instructions.length) {
            setInstructionIndex(instructionIndex + 1);
        }
    };

    const handleBack = () => {
        if (instructionIndex > 0) {
            setInstructionIndex(instructionIndex - 1);
        }
    };

    const handleFinish = () => {
        setOpen(false);
    };

    return (
        <Container>
            <Typography variant="h6" gutterBottom color="white" textAlign="center" fontFamily="Minecraftia" sx={{ mb: 3 }} >
                {message} <br /> <br /> {progress}% <br /> <br /> DO NOT REFRESH THE PAGE
            </Typography>
            <Box sx={{
                width: '100%',
                height: '20px',
                bgcolor: '#5a5a5a',
                borderRadius: '10px', // Rounded corners for progress bar
                border: '2px solid #000000',
                boxSizing: 'border-box',
                position: 'relative'
            }}>
                <Box sx={{
                    height: '100%',
                    width: `${progress}%`,
                    bgcolor: '#71e849',
                    transition: 'width .4s ease-in-out',
                    position: 'relative',
                    overflow: 'hidden',
                }} />
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                    <Link href="https://discord.gg/g6aSCqyEm8" target="_blank" underline="none" sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
                        <Typography sx={{ ml: 1 }} variant="body1" color="white" fontFamily="Minecraftia">Join our Discord: </Typography>
                        <img src="https://img.icons8.com/?size=100&id=114902&format=png&color=000000" alt="Discord Icon" width="50" />
                    </Link>
                </Box>
                <IconButton
                    color="primary"
                    onClick={handleOpen}
                    sx={{ position: 'absolute', right: -50, bottom: 150 }}
                >
                    <InfoIcon />
                </IconButton>
                <Modal
                    open={open}
                    onClose={showAllInstructions ? handleClose : null}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    BackdropProps={{
                        style: {
                            backgroundColor: 'rgba(0, 0, 0, 0.8)' // Darker backdrop
                        }
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: isSmallScreen ? '90%' : '50%', 
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            borderRadius: '10px', // Rounded corners for the instruction panel
                            overflow: 'auto',
                            padding: '50px',
                        }}
                    >
                        {showAllInstructions && (
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{ position: 'absolute', right: 8, top: 8 }}
                            >
                                <CloseIcon />
                            </IconButton>
                        )}

                        {!showAllInstructions ? (
                            <>
                                <Typography id="modal-modal-title" variant="h5" component="h2">
                                    {`Instruction ${instructionIndex + 1}`}
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    <span dangerouslySetInnerHTML={{ __html: instructions[instructionIndex] }} />
                                </Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                                    <Button onClick={handleBack} disabled={instructionIndex === 0}>
                                        Back
                                    </Button>
                                    {instructionIndex < instructions.length - 1 ? (
                                        <Button 
                                            onClick={handleNext} 
                                            sx={{ bgcolor: '#71e849', color: '#fff', '&:hover': { bgcolor: '#5ca338' } }} // Green Next button
                                        >
                                            Next
                                        </Button>
                                    ) : (
                                        <>
                                            <iframe 
                                                width="100%" 
                                                height="250" 
                                                src="https://www.youtube-nocookie.com/embed/4gRGcRPE1_0?autoplay=1" 
                                                title="YouTube video player" 
                                                frameBorder="0" 
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" 
                                                allowFullScreen
                                            />
                                            <Button 
                                                onClick={handleFinish}
                                                sx={{ bgcolor: '#e63946', color: '#fff', '&:hover': { bgcolor: '#d62839' } }}
                                            >
                                                Finish
                                            </Button>
                                        </>
                                    )}
                                </Box>
                            </>
                        ) : (
                            <>
                                <Typography id="modal-modal-title" variant="h5" component="h2">
                                    Instructions
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    <ol>
                                        {instructions.map((instr, idx) => (
                                            <li key={idx}>{instr}</li>
                                        ))}
                                    </ol>
                                    <iframe 
                                        width="100%" 
                                        height="250" 
                                        src="https://www.youtube-nocookie.com/embed/4gRGcRPE1_0?autoplay=1" 
                                        title="YouTube video player" 
                                        frameBorder="0" 
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" 
                                        allowFullScreen
                                    />
                                </Typography>
                            </>
                        )}
                    </Box>
                </Modal>
            </Box>
        </Container>
    );
}