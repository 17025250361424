import * as React from 'react';
import CustomButton from './components/CustomButton';
import LoadingScreen from './components/LoadingScreen';
import Fade from '@mui/material/Fade';
import {
    Grid,
    Typography,
    Slider,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TextField,
    Button,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import InstructionPanel from './components/InstructionPanel';
import ConsentForm from './components/ConsentForm';
import deployment_config from './deployment_config.json';
import { storeEmail } from './utils';

export default function UserForm({ email, individualId, organizationName, name, setName, age, setAge, experience, setExperience, gender, setGender, consent, setConsent, setCurrentForm, subscribe, setSubscribe }) {

    const [maleNames, setMaleNames] = React.useState([]);
    const [femaleNames, setFemaleNames] = React.useState([]);
    const [randomNames, setRandomNames] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const [parentalEmail, setParentalEmail] = React.useState('');
    const [parentalConsent, setParentalConsent] = React.useState(false);
    const [parentalEmailError, setParentalEmailError] = React.useState(false);
    const [isNameFetched, setIsNameFetched] = React.useState(false);
    const apiBaseUrl = deployment_config.apiBaseUrl;

    console.log(email);

    const clear = () => {
        setName('');
        setAge(14);
        setExperience('');
        setGender('');
        setConsent(false);
        setSubscribe(false);
    }

    const handleParentalEmail = (e) => {
        const value = e.target.value;
        setParentalEmail(value);

        // simple regular expression to validate an email address
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const isValidEmail = emailRegex.test(value);

        setParentalEmailError(!isValidEmail);
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default form submit action
            if (name && age && gender && experience && consent &&
                (age >= 14 || (age < 14 && parentalEmail && !parentalEmailError && parentalConsent))) {
                setLoading(true);
                handleSubmit();
            }
        }
    }

    const handleSubmit = () => {
        storeEmail(email);
        // Check ages
        if (age < 14 && age >= 7) {
            // Send email to parent
            fetch(apiBaseUrl + '/send-parental-consent-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "email": email,
                    "parentEmail": parentalEmail
                })
            })
                .catch(error => {
                    alert("An error occurred when sending an email to your parent/guardian email.")
                    return;
                })
        } else if (age < 7) {
            // Not eligible so don't submit
            return;
        } else {
            // Age is 17+
            setParentalConsent(true);
        }

        const jsonObject = {
            "email": email,
            "name": name,
            "age": age,
            "experience": experience,
            "gender": gender,
            "consent": consent,
            "parentalConsent": parentalConsent,
            "individualId": individualId,
            "organizationName": organizationName,
            "subscribe": subscribe
        };

        fetch(apiBaseUrl + '/new-user-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jsonObject)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then((data) => {
                        console.log(data)
                        const dataString = JSON.stringify(data);

                        throw new Error(dataString)
                    })
                }
            })
            .then(data => {
                setCurrentForm('token');
            })
            .catch(error => {
                console.log(error)
                alert(`An error occurred while processing your request: ${error}, please report this to us`);
                setLoading(false)
            });
    }

    const fetchRandomNames = () => {
        setLoading(true);
        fetch(`${apiBaseUrl}/extract-name-from-pool`)
            .then(response => response.json())
            .then(data => {
                setMaleNames(data.male_names || []);
                setFemaleNames(data.female_names || []);
                setLoading(false); // Hide loading indicator once names are fetched
                setIsNameFetched(true); // Indicate that names have been fetched
            })
            .catch(error => {
                console.error("Failed to fetch names", error);
                setLoading(false);
                setIsNameFetched(true); // Indicate that names have been fetched, even if there was an error
            });
    };

    React.useEffect(() => {
        fetchRandomNames();
    }, []);

    React.useEffect(() => {
        if (isNameFetched) {
            if (maleNames.length > 0 || femaleNames.length > 0) {
                setRandomNames([...new Set([...maleNames, ...femaleNames])]);
            } else {
                console.error("running out of names, please contact the development team")
                alert("Running out of names, please contact the development team");
            }
        }
    }, [maleNames, femaleNames, isNameFetched]);

    return (
        <>
            {isLoading ?
                <Fade in={isLoading} timeout={300}>
                    <div>
                        <LoadingScreen />
                    </div>
                </Fade>
                :
                <form noValidate autoComplete="off" onKeyDown={handleKeyPress}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <InstructionPanel
                                instructions={
                                    <ol>
                                        <li>Select one of our random names! This is what you will be called so you remain anonymous.</li>
                                        <li>Please enter your age and gender so we can make sure our research is accurate!</li>
                                        <li>Tell us how experienced you are in Minecraft! Are you a noob or a pro?</li>
                                        <li>Read through our consent form and click the green accept box at the end!</li>
                                        <li>Finally, click submit!</li>
                                    </ol>
                                }
                                iframe={
                                    <iframe
                                        width="100%"
                                        height="266"
                                        src="https://www.youtube-nocookie.com/embed/HDJnfH8Hw14?autoplay=1"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                                        allowfullscreen
                                    >
                                    </iframe>
                                }
                            />
                        </Grid>
                        <Grid container item direction="row" alignItems="center" spacing={0}>
                            <Grid item xs={1.4}>
                                <Typography id='discrete-slider' gutterBottom>
                                    Age:
                                </Typography>
                            </Grid>
                            <Grid item xs={8.9}>
                                <Slider
                                    aria-label='Default'
                                    defaultValue={null}
                                    valueLabelDisplay='auto'
                                    value={age}
                                    min={1}
                                    max={99}
                                    onChange={(e) => setAge(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={1.7}>
                                <TextField
                                    value={age}
                                    size="small"
                                    variant="outlined"
                                    type="text" // Set type as text
                                    inputProps={{
                                        style: { textAlign: 'center' },
                                        pattern: "\\d*", // Pattern to accept only digits
                                    }}
                                    onChange={(e) => {
                                        // Allow only numbers in the input and restrict the value to 0-99
                                        const value = e.target.value;
                                        if (value === '') {
                                            setAge(0);
                                            return;
                                        }

                                        if (/^\d+$/.test(value)) {
                                            const numericValue = Number(value); // Convert to number to remove leading zeros
                                            if (numericValue >= 0 && numericValue <= 99) {
                                                setAge(numericValue.toString()); // Convert back to string
                                            }
                                        }
                                    }}
                                    sx={{
                                        marginLeft: 2.8,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {age >= 7 ?
                            <>
                                <Grid item>
                                    <FormControl fullWidth>
                                        <InputLabel>Name</InputLabel>
                                        <Select
                                            label="Name"
                                            value={name}
                                            onChange={(e) => { setName(e.target.value); }}
                                            MenuProps={{
                                                disableScrollLock: true,
                                            }}
                                        >
                                            {randomNames.map((name) => (
                                                <MenuItem key={name} value={name}>{name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl fullWidth>
                                        <InputLabel>Gender</InputLabel>
                                        <Select
                                            label="Gender"
                                            value={gender}
                                            onChange={(e) => { setGender(e.target.value); }}
                                            MenuProps={{
                                                disableScrollLock: true,
                                            }}
                                        >
                                            <MenuItem value={"Male"}>Male</MenuItem>
                                            <MenuItem value={"Female"}>Female</MenuItem>
                                            <MenuItem value={"Non-binary"}>Non-binary</MenuItem>
                                            <MenuItem value={"Agender"}>Agender</MenuItem>
                                            <MenuItem value={"Two-Spirited"}>Two-Spirited</MenuItem>
                                            <MenuItem value={"Other"}>Other</MenuItem>
                                            <MenuItem value={"Prefer not to say"}>Prefer not to say</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl fullWidth>
                                        <InputLabel>Experience</InputLabel>
                                        <Select
                                            label="Experience"
                                            value={experience}
                                            onChange={(e) => { setExperience(e.target.value); }}
                                            MenuProps={{
                                                disableScrollLock: true,
                                            }}
                                        >
                                            <MenuItem value={"Beginner"}>Beginner - I'm completely new to Minecraft</MenuItem>
                                            <MenuItem value={"Amateur"}>Amateur - I've played a few hours of Minecraft</MenuItem>
                                            <MenuItem value={"Regular"}>Regular - I regularly play Minecraft</MenuItem>
                                            <MenuItem value={"Pro"}>Pro - I am an experienced and pro Minecraft Player</MenuItem>
                                            <MenuItem value={"Veteran"}>Veteran - Been here since the old days. (Before 2015)</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {age < 14 && age >= 7 &&
                                    <Grid item container direction="column" spacing={2}>
                                        <Grid item>
                                            <TextField
                                                error={parentalEmailError}
                                                helperText={parentalEmailError ? "Invalid email address" : ""}
                                                label="Parent/Guardian Email"
                                                variant="outlined"
                                                fullWidth
                                                value={parentalEmail}
                                                onChange={handleParentalEmail}
                                                required
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                required
                                                disabled={age >= 18}
                                                checked={age >= 18 || parentalConsent}
                                                value={true}
                                                control={
                                                    <Checkbox
                                                        onChange={(e) => {
                                                            if (age < 18) {
                                                                setParentalConsent(e.target.checked);
                                                            }
                                                        }}
                                                    />
                                                }
                                                label="I confirm that my parent/guardian email is correct and I also assent to participation in this research study."
                                            />
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item>
                                    <ConsentForm consent={consent} setConsent={setConsent} />
                                    {!consent && <Button color="error">Consent is required to proceed</Button>}
                                    {consent && <Button color="success">Consent Granted</Button>}
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={subscribe}
                                                onChange={(e) => setSubscribe(e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                        label={<Typography sx={{ fontSize: 16 }}>Email me when other players are waiting to play PLAICraft.</Typography>}
                                    />
                                </Grid>
                                <Grid container item direction="row" alignItems="center" spacing={0} sx={{ display: "flex", justifyContent: "center" }}>
                                    <Grid item>
                                        <CustomButton
                                            isDisabled={
                                                !name ||
                                                !age ||
                                                !gender ||
                                                !experience ||
                                                !consent ||
                                                (age < 14 && !parentalEmail) ||
                                                (age < 14 && parentalEmailError)
                                            }
                                            content="Submit"
                                            color="success"
                                            onClick={() => {
                                                setLoading(true);
                                                handleSubmit();
                                            }}
                                        />
                                    </Grid>
                                    <Grid item ml={2}>
                                        <CustomButton
                                            isDisabled={false}
                                            content="Clear"
                                            color="primary"
                                            onClick={clear}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                            :
                            <Grid container item alignItems="center" justifyContent="center" spacing={0}>
                                <Grid item>
                                    <Typography variant="h6">
                                        Sorry, must be at least 7 years old to participate. 
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ marginTop: 1 }}>
                                    <SentimentVeryDissatisfiedIcon sx={{ fontSize: 100 }} />
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </form>
            }
        </>
    );
}
