// src/utils.js

// Helper function to convert ArrayBuffer to a base64 string (for storage)
function arrayBufferToBase64(buffer) {
    return btoa(String.fromCharCode(...new Uint8Array(buffer)));
}

// Helper function to convert base64 string back to ArrayBuffer
function base64ToArrayBuffer(base64) {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

// Generate a symmetric encryption key and store it in sessionStorage
async function getOrCreateKey() {
    const storedKey = sessionStorage.getItem('encryptionKey');
    if (storedKey) {
        // If key exists in sessionStorage, use it
        return crypto.subtle.importKey(
            'raw',
            base64ToArrayBuffer(storedKey),
            'AES-GCM',
            true,
            ['encrypt', 'decrypt']
        );
    } else {
        // If key doesn't exist, create a new one and store it
        const key = await crypto.subtle.generateKey(
            {
                name: 'AES-GCM',
                length: 256,
            },
            true,
            ['encrypt', 'decrypt']
        );
        const keyBuffer = await crypto.subtle.exportKey('raw', key);
        sessionStorage.setItem('encryptionKey', arrayBufferToBase64(keyBuffer));
        return key;
    }
}

// Encrypt the email
async function encryptEmail(email, key) {
    const encoder = new TextEncoder();
    const encodedEmail = encoder.encode(email);

    // Create a random initialization vector
    const iv = crypto.getRandomValues(new Uint8Array(12));

    // Encrypt the email
    const encryptedEmail = await crypto.subtle.encrypt(
        {
            name: 'AES-GCM',
            iv: iv,
        },
        key,
        encodedEmail
    );

    // Combine the IV with the encrypted email for later decryption
    return {
        iv: arrayBufferToBase64(iv),
        email: arrayBufferToBase64(encryptedEmail),
    };
}

// Decrypt the email
async function decryptEmail(encryptedData, key) {
    const { iv, email } = encryptedData;

    const decryptedEmail = await crypto.subtle.decrypt(
        {
            name: 'AES-GCM',
            iv: base64ToArrayBuffer(iv),
        },
        key,
        base64ToArrayBuffer(email)
    );

    const decoder = new TextDecoder();
    return decoder.decode(decryptedEmail);
}

// Storing the encrypted email
export async function storeEmail(email) {
    if (email) {
        try {
            const key = await getOrCreateKey(); // Get or create encryption key
            const encryptedData = await encryptEmail(email, key);

            const encryptedEmailString = JSON.stringify(encryptedData);

            localStorage.setItem('email', encryptedEmailString);
            sessionStorage.setItem('email', encryptedEmailString);

            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set('email', encodeURIComponent(encryptedEmailString)); // URL encode the encrypted email
            window.history.replaceState(null, null, "?" + urlParams.toString());
        } catch (error) {
            console.error("Failed to encrypt and store email:", error);
        }
    } else {
        console.error("Attempted to store empty or invalid email.");
    }
}

// Getting the encrypted email
export async function getEmail() {
    let encryptedEmailString = localStorage.getItem('email') || sessionStorage.getItem('email');
    
    if (!encryptedEmailString) {
        const urlParams = new URLSearchParams(window.location.search);
        encryptedEmailString = decodeURIComponent(urlParams.get('email'));
    }

    if (!encryptedEmailString) {
        console.warn("Encrypted email not found in any storage.");
        return null;
    }

    try {
        const encryptedData = JSON.parse(encryptedEmailString);
        const key = await getOrCreateKey();  // Use the same key for decryption
        return await decryptEmail(encryptedData, key);
    } catch (error) {
        console.error("Failed to decrypt and retrieve email:", error);
        return null;
    }
}
